import { EventEmitter, Injectable } from '@angular/core';
import { SuggestionDto } from '../../dto/follow/suggestion.dto';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAuth } from '../auth/iauth.interface';
import { ICommonControllerHelper } from '../../helpers/common/icommon-controller.helper';
import { IBlog } from './iblog.interface';
import { EventLoading } from '../../dto/events-loading.dto';
import { BlogDto } from '../../dto/blog/blog.dto';
import { ResponseBlog } from '../../dto/blog/responseblog.dto';

@Injectable({
  providedIn: 'root'
})
export class BlogService implements IBlog{
  private postChannel:EventEmitter<BlogDto[]> = new EventEmitter();
  private eventChannel:EventEmitter<EventLoading> = new EventEmitter();
  private server_key: string = environment.server_key;
  private app_url: string = environment.APP_URL;
  private post:BlogDto;
  constructor(
    private http: HttpClient,
    private auth: IAuth,
    private common: ICommonControllerHelper
  ) {
  } 
  async blog(user_id: string): Promise<boolean> {
    try {
      let udata = await this.auth.userData();
      var formData: any = new FormData();
      formData.append("user_id", user_id);
      formData.append("server_key", this.server_key); 
      const url = `${this.app_url}follow-user?access_token=${udata.access_token}`;
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as any;
      if (data.api_status == 200) { 
        return true;
      }
    } catch (err) {
      // this.logger.error(err, ''); 
    }
    return false;
  }
   
  get(): BlogDto {
    return this.post;
  }
  set(model: BlogDto) {
   this.post= model;
  }
  
  channel(): EventEmitter<BlogDto[]> {
    return this.postChannel;
  }
  events(): EventEmitter<EventLoading> {
    return this.eventChannel;
  }
   
  async request(): Promise<BlogDto[]> {
    try {
      let udata = await this.auth.userData();
      var formData: any = new FormData(); 
      formData.append("server_key", this.server_key); 
      formData.append("limit", "100");
      this.eventChannel.emit(EventLoading.loading);
      const url = `${this.app_url}get-articles?access_token=${udata.access_token}`;
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as ResponseBlog;
      if (data.api_status == 200) {
        if (this.postChannel!=null){
          this.postChannel.emit(data.articles);
          this.eventChannel.emit(EventLoading.loadingEnd);
        }
        return data.articles;
      }
    } catch (err) {
      // this.logger.error(err, '');
      throw err;
    }
  }
}
