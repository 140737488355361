import { EventEmitter } from "@angular/core";
import { NewPostDto } from "../../dto/post/new-post.dto";
import { Post } from "../../dto/post/post.dto";
import { PostResponse } from "../../dto/post/response-post.dto";

export abstract class IPost {
  abstract channel(): EventEmitter<Post[]>;
  abstract events(): EventEmitter<Events>;
  abstract requestPosts(): Promise<Post[]>; 
  abstract newPost(model:NewPostDto): Promise<PostResponse<Post>>; 
  abstract setPost(model:Post);
  abstract getPost():Post;
  abstract addComment(text:string,action:ActionComment): Promise<boolean>;
}


export enum Events {
  loading,
  loadingEnd
}
export enum ActionComment {
  comment = "comment",
  edit = "edit",
  delete= "delete",
  like= "like",
  dislike= "dislike",
  wonder= "wonder"
}