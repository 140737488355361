import { Component, Input, OnInit } from "@angular/core";
import { LoadingController, ModalController } from "@ionic/angular";
import { IGeneral } from "src/app/shared/services/general/igeneral.interface";
@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent<T> implements OnInit {
  @Input() data: any;
  formData: any = { searchText: "" };
  items = [];
  searchText = "";

  constructor(
    private readonly modalCtrl: ModalController,
    private general: IGeneral,
    private loadingCtrl: LoadingController
  ) {}

  async cloneList() {
    /*this.productsCopy = ObjectUtils.clone(this.products);
    this.serviceBeneficiariesCopy = ObjectUtils.clone(
      this.serviceBeneficiaries
    );*/
  }
  async back() {
    await this.modalCtrl.dismiss();
  }

  async ngOnInit() {
    this.loadingCtrl.create({
      message: "This Loader Will Auto Hide in five Seconds",
    });
    this.items = [];

    this.general.getList<T>(this.data.enpdoint).then((data) => {
      if (data.isValid) {
        for (let i in data.data) {
          this.items.push(data.data[i]);
        }
      }
    });
  }
  async changeTapOption(index: number) {}
  async selectItem(item: any) {
    await this.modalCtrl.dismiss(item);
  }

  public async searchItem(event) {
    this.searchText = event.detail.value.toLowerCase();
  }
}
