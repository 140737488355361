import { EventEmitter } from "@angular/core";
import {  SuggestionDto } from "../../dto/follow/suggestion.dto";
 

export abstract class IFollow {
  abstract channel(): EventEmitter<SuggestionDto[]>;
  abstract events(): EventEmitter<Events>;
  abstract request(): Promise<SuggestionDto[]>; 
  abstract follow(user_id:string): Promise<boolean>; 
  abstract set(model:SuggestionDto);
  abstract get():SuggestionDto;
}


export enum Events {
  loading,
  loadingEnd
}
export enum TypeNotificacion {
  comment = "comment",
  reaction = "reaction",
  shared_your_post= "shared_your_post"
}