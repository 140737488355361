import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageHelper {
  constructor() {}

  async set(skey: string, svalue: string): Promise<void> {
    //console.debug('Storage', `Writing key ${key}`);

    await Preferences.set({
      key: skey,
      value: svalue,
    });

    //console.debug('Storage', `The key ${key} was written.`);
  }

  async clear(): Promise<void> {
    //  console.debug('Storage', `Clearing storage.`);
    await Preferences.clear();
    // console.debug('Storage', `Storage cleared.`);
  }

  async remove(skey: string): Promise<void> {
    await Preferences.remove({ key: skey });
  }

  async get(skey: string): Promise<string> {
    if (skey != null) {
      console.debug('Storage', `Looking for key ${skey}`);
      const data = await Preferences.get({ key: skey });

      if (data != null) {
        console.debug('Storage', `Key ${skey} found.`);
        return data.value;
      }

      console.debug('Storage', `Key ${skey} not found.`);
    }
    return null;
  }
}
