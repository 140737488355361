import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Response } from "../../dto/response.dto";
import { ActionComment, Events, IPost } from "./ipost.interface";
import { Post } from "../../dto/post/post.dto";
import { IAuth } from "../auth/iauth.interface";
import { NewPostDto } from "../../dto/post/new-post.dto";
import { PostResponse } from "../../dto/post/response-post.dto";
import { ICommonControllerHelper } from "../../helpers/common/icommon-controller.helper";

@Injectable({ providedIn: "root" })
export class PostService implements IPost {
  private postChannel:EventEmitter<Post[]> = new EventEmitter();
  private eventChannel:EventEmitter<Events> = new EventEmitter();
  private server_key: string = environment.server_key;
  private app_url: string = environment.APP_URL;
  private post:Post;
  constructor(
    private http: HttpClient,
    private auth: IAuth,
    private common: ICommonControllerHelper
  ) {
  }
  async addComment(text: string,action:ActionComment): Promise<boolean> {
    if (this.post){
      let ret:PostResponse<Post> = null;
      try {
        let udata = await this.auth.userData();
        var formData: any = new FormData();
       // formData.append("user_id", udata.user_id);
        formData.append("server_key", this.server_key);
        formData.append("post_id", this.post.post_id);
        formData.append("text", text);
        formData.append("action", action);
        console.log(action)
        /*if (model.image != null){
          const response = await fetch(model.image.data);
          const blob = await response.blob();
          formData.append("postFile",blob, model.image.name); 
        }*/
        this.common.showLoading();
        const url = `${this.app_url}post-actions?access_token=${udata.access_token}`;
        const data = (await this.http
          .post(url, formData)
          .toPromise()) as PostResponse<Post>;
          ret=data;
          this.common.hideLoading();
        if (data.api_status == 200) {
          return true;
        }
      } catch (err) {
        return false;
      } 
    }
    return false;
  }
  getPost(): Post {
    return this.post;
  }
  setPost(model: Post) {
   this.post= model;
  }
  
  channel(): EventEmitter<Post[]> {
    return this.postChannel;
  }
  events(): EventEmitter<Events> {
    return this.eventChannel;
  }
  
  async newPost(model: NewPostDto): Promise<PostResponse<Post>> {
    let ret:PostResponse<Post> = null;
    try {
      let udata = await this.auth.userData();
      var formData: any = new FormData();
      //formData.append("user_id", udata.user_id);
      formData.append("server_key", this.server_key);
      formData.append("postText", model.postText);
      if (model.image != null){
        const response = await fetch(model.image.data);
        const blob = await response.blob();
        formData.append("postFile",blob, model.image.name); 
      }
      this.common.showLoading();
      const url = `${this.app_url}new_post?access_token=${udata.access_token}`;
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as PostResponse<Post>;
        ret=data;
        this.common.hideLoading();
      if (data.api_status == 200) {
        return data;
      }
    } catch (err) {
      throw err;
    }
    return ret;
  }

  async requestPosts(): Promise<Post[]> {
    try {
      let udata = await this.auth.userData();
      var formData: any = new FormData();
     // formData.append("user_id", udata.user_id);
      formData.append("fetch", "post_data");
      formData.append("server_key", this.server_key);
      formData.append("type", "get_news_feed");
      formData.append("limit", "100");
      this.eventChannel.emit(Events.loading);
      const url = `${this.app_url}posts?access_token=${udata.access_token}`;
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as Response<Post[]>;
      if (data.api_status == 200) {
        if (this.postChannel!=null){
          this.postChannel.emit(data.data);
          this.eventChannel.emit(Events.loadingEnd);
        }
        return data.data;
      }
    } catch (err) {
      // this.logger.error(err, '');
      throw err;
    }
  }
}
