import { CreateAccountDto } from '../../dto/create-account.dto';
import { Credentials } from '../../dto/credentials.dto';
import { Response } from '../../dto/response.dto';
import { UserDataDto } from '../../dto/user-data.dto';
import { UserInfoDto } from '../../dto/user-info.dto';
import { UserProfileDto } from '../../dto/user-profile.dto';
export abstract class IAuth {
  abstract doLogin(user: Credentials): Promise<boolean>;
  abstract createUser(accountData: CreateAccountDto): Promise<UserDataDto> ;
  abstract userData(): Promise<UserDataDto>;
  abstract updateUserData(accountData: UserProfileDto) : Promise<UserInfoDto>;
  abstract LogOut(): Promise<void>;
}
