import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { IAuth } from './shared/services/auth/iauth.interface';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private auth: IAuth, private router: Router,@Inject(APP_CONFIG) public config: AppConfig,private translate: TranslateService, ) {}

  ngOnInit() {
    this.validLogin();
    this.globalize("en");
  }
  async validLogin() {
    const user = await this.auth.userData();
    if (user && user.access_token) {
      this.router.navigate(['home']);
    }
  }

  globalize(languagePriority) {
    this.translate.setDefaultLang("en");
    let defaultLangCode = this.config.availableLanguages[0].code;
    this.translate.use(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
    //this.setDirectionAccordingly(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
  }
}
