export interface Response<T> {
  data: T;
  api_status: number;
}

export class GResponse<T> {
  isValid: boolean;
  data: T;
  message: string[];
}
