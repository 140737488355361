import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { UserDataDto } from "../../dto/user-data.dto";
import { IAuth } from "./iauth.interface";
import { HttpClient } from "@angular/common/http";
import { Credentials } from "../../dto/credentials.dto";
import { Response } from "../../dto/response.dto";
import { StorageHelper } from "../../helpers/storage.helper";
import { CreateAccountDto } from "../../dto/create-account.dto";
import { UserInfoDto } from "../../dto/user-info.dto";
import { UserProfileDto } from "../../dto/user-profile.dto";
import { ICommonControllerHelper } from "../../helpers/common/icommon-controller.helper";

@Injectable({ providedIn: "root" })
export class AuthService implements IAuth {
  private userInfo: UserDataDto = null;
  private server_key: string = environment.server_key;
  private app_url: string = environment.APP_URL;
  constructor(
    private http: HttpClient,
    private storage: StorageHelper,
    private loadingCtrl: ICommonControllerHelper
  ) {}

  async doLogin(user: Credentials): Promise<boolean> {
    try {
      user.server_key = this.server_key;
      user.device_id = "2323";

      const url = `${this.app_url}auth/login`;
      const data = await this.http
        .post<any>(url, JSON.stringify(user))
        .toPromise();
      this.userInfo = data.data;
      environment.JWT_TOKEN = this.userInfo.access_token;
    //  console.log("environment.JWT_TOKEN ", environment.JWT_TOKEN);
      await this.storage.set("userInfo", JSON.stringify(this.userInfo));
      return data.isValid;
    } catch (err) {
    //  console.log("environment.JWT_TOKEN ", err);
      // this.logger.error(err, 'basicLogin err');
      return false;
    }
  }

  async loadUserData(udata: UserDataDto): Promise<UserInfoDto> {
    try {
      var formData: any = new FormData();
      //formData.append('user_id', udata.user_id);
      formData.append("fetch", "user_data");
      formData.append("server_key", this.server_key);
      const url = `${this.app_url}get-user-data?access_token=${udata.access_token}`;
      const data = (await this.http.post(url, formData).toPromise()) as any;
      if (data.api_status == 200) {
        return data.user_data as UserInfoDto;
      }
      return null;
    } catch (err) {
      // this.logger.error(err, 'basicLogin err');
    }
    return null;
  }
  async createUser(accountData: CreateAccountDto): Promise<UserDataDto> {
    try {
      var formData: any = new FormData();
      formData.append("username", accountData.username);
      formData.append("password", accountData.password);
      formData.append("email", accountData.email);
      formData.append("confirm_password", accountData.confirm_password);
      formData.append("server_key", this.server_key);
      formData.append("first_name", accountData.username);
      formData.append("last_name", accountData.username);
      const url = `${this.app_url}create-account`;
      this.loadingCtrl.showLoading();
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as UserDataDto;
      /*
       if (data.api_status==200){
          this.userInfo = data;
          await this.storage.set('userInfo', JSON.stringify(this.userInfo));
          this.loadingCtrl.hideLoading();
          return data;
       }  */
      this.loadingCtrl.hideLoading();
      return data;
    } catch (err) {
      // this.logger.error(err, 'basicLogin err');
      throw err;
    }
  }
  async userData(): Promise<UserDataDto> {
    const user = JSON.parse(await this.storage.get("userInfo")) as UserDataDto; 
    return user;
  }
  async LogOut(): Promise<void> {
    await this.storage.remove("userInfo");
  }

  async updateUserData(accountData: UserProfileDto): Promise<UserInfoDto> {
    try {
      let udata = await this.userData();
      var formData: any = new FormData();
      formData.append("server_key", this.server_key);
      /// formData.append('user_id', udata.user_id);
      if (accountData.avatar != null) {
        const response = await fetch(accountData.avatar.data);
        const blob = await response.blob();
        formData.append("avatar", blob, accountData.avatar.name);
      }
      if (accountData.first_name != null) {
        formData.append("first_name", accountData.first_name);
      }
      if (accountData.last_name != null) {
        formData.append("last_name", accountData.last_name);
      }
      if (accountData.phone_number != null) {
        formData.append("phone_number", accountData.phone_number);
      }
      //formData.append('username', accountData.username);
      //formData.append('password', accountData.password);
      //formData.append('email', accountData.email);
      //formData.append('confirm_password', accountData.confirm_password);
      /*
      formData.append('first_name', accountData.username);
      formData.append('last_name', accountData.username);*/
      const url = `${this.app_url}update-user-data?access_token=${udata.access_token}`;
      this.loadingCtrl.showLoading();
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as Response<string>;

      if (data.api_status == 200) {
        let info = await this.loadUserData(udata);
        console.log("updateUserData", info);
        //  udata.user_data = info;
        await this.storage.set("userInfo", JSON.stringify(udata));
        this.loadingCtrl.hideLoading();
        return info;
      }
      this.loadingCtrl.hideLoading();
      return null;
    } catch (err) {}
    return null;
  }
}
