import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { ICommonControllerHelper } from "./icommon-controller.helper";
import { AlertController, LoadingController } from "@ionic/angular";

@Injectable({ providedIn: "root" })
export class CommonControllerHelper implements ICommonControllerHelper {
  private server_key: string = environment.server_key;
  private app_url: string = environment.APP_URL;
  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {}

  async showAlert(title: string, subTitle: string) {
    let alert = this.alertCtrl.create({
        header: title,
        message: subTitle,
        buttons: ["OK"],
        animated:true,
      });
      (await alert).present();
      
  }
  async showLoading() {
    const loading = await this.loadingCtrl.create({
    });

    loading.present();
  }
  hideLoading() {
    this.loadingCtrl.dismiss();
  }
}
