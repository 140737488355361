import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AuthService } from "./services/auth/auth.service";
import { IAuth } from "./services/auth/iauth.interface";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { StorageHelper } from "./helpers/storage.helper";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IPost } from "./services/post/ipost.interface";
import { PostService } from "./services/post/post.service";
import { CommonControllerHelper } from "./helpers/common/common-controller.helper";
import { ICommonControllerHelper } from "./helpers/common/icommon-controller.helper";
import { CommonMediaHelper } from "./helpers/common/common-media.helper";
import { ICommonMediaHelper } from "./helpers/common/icommon-media.helper";
import { INotification } from "./services/notification/inotification.interface";
import { NotificationService } from "./services/notification/notification.service";
import { HeaderComponent } from "./components/header.component";
import { IFollow } from "./services/follow/ifollow.interface";
import { FollowService } from "./services/follow/follow.service";
import { IBlog } from "./services/blog/iblog.interface";
import { BlogService } from "./services/blog/blog.service";
import { RequestInterceptor } from "./helpers/common/request-interceptor.helper";
import { IGeneral } from "./services/general/igeneral.interface";
import { GeneralService } from "./services/general/general.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    NgxSpinnerModule,
    IonicModule,
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    TranslateModule, 
  ],
  imports: [
    NgxSpinnerModule,
    IonicModule,
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    TranslateModule,      
    TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),    
  ],
  providers: [
    { useClass: AuthService, provide: IAuth },
    { useClass: PostService, provide: IPost },
    { useClass: CommonControllerHelper, provide: ICommonControllerHelper },
    { useClass: CommonMediaHelper, provide: ICommonMediaHelper },
    { useClass: NotificationService, provide: INotification },
    { useClass: FollowService, provide: IFollow },
    { useClass: BlogService, provide: IBlog },
    { useClass: GeneralService, provide: IGeneral },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    StorageHelper
  ],
  declarations: [],
})
export class SharedModule {}
