import { EventEmitter } from "@angular/core";
import {  SuggestionDto } from "../../dto/follow/suggestion.dto";
import { EventLoading } from "../../dto/events-loading.dto";
import { BlogDto } from "../../dto/blog/blog.dto";
 

export abstract class IBlog {
  abstract channel(): EventEmitter<BlogDto[]>;
  abstract events(): EventEmitter<EventLoading>;
  abstract request(): Promise<BlogDto[]>; 
  abstract blog(user_id:string): Promise<boolean>; 
  abstract set(model:BlogDto);
  abstract get():BlogDto;
}
