import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import {  Platform } from "@ionic/angular";
import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera";
import { Directory, FileInfo, Filesystem } from "@capacitor/filesystem";
import { ICommonMediaHelper } from "./icommon-media.helper";

@Injectable({ providedIn: "root" })
export class CommonMediaHelper implements ICommonMediaHelper {
  private server_key: string = environment.server_key;
  private app_url: string = environment.APP_URL;
  IMAGE_DIR = "images";
  private images: any[] = [];
  private eventChannel:EventEmitter<any> = new EventEmitter();

  constructor(
    private platform: Platform
  ) {
     this.start();
  }
  events(): EventEmitter<any> {
    return this.eventChannel;
  }
  async initMedia() {
    this.start();
  }
  defaultImagePath(path: string) {
    this.IMAGE_DIR = path;
  }
  async start(){
    await this.createUploadDirectory();
    await this.deleteImages();
  }
  async takePicture() {
    /*
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
    });
    if (image) {
      const data: TextDetections = await Ocr.detectText({ filename: image.path});
      for (let detection of data.textDetections) {
          console.log(detection.text);
      }

      await this.savePicture(image);
      await this.createUploadDirectory();
      await this.deleteImages();
    }*/
  }

  async createUploadDirectory() {
    await Filesystem.readdir({
      directory: Directory.Data,
      path: this.IMAGE_DIR,
    })
      .then(
        async (result) => {
          console.log("readdir",result.files)
          await this.loadFileData(result.files);
        },
        async (err) => {
          await Filesystem.mkdir({
            directory: Directory.Data,
            path: this.IMAGE_DIR,
          });
        }
      )
      .then((_) => {});
  }

  async loadFileData(filesNames: FileInfo[]) {
    for (let f of filesNames) {
      const filepath = `${this.IMAGE_DIR}/${f.name}`;
      const readFile = await Filesystem.readFile({
        directory: Directory.Data,
        path: filepath,
      });

      this.images.push({
        name: f.name,
        path: filepath,
        data: `data:image/jpeg;base64,${readFile.data}`,
        ...f,
      });
    }
    this.eventChannel.emit(this.images); 
  }

  async deleteImages() {
    for (let f of this.images) {
      await Filesystem.deleteFile({
        directory: Directory.Data,
        path: f.path,
      });
    }
    this.images = [];
  }
  
  async savePicture(photo: Photo) { 
    const base64Data = await this.readAsBase64(photo);
    const fileName = new Date().getTime() + ".jpeg";

    const savedFile = await Filesystem.writeFile({
      path: `${this.IMAGE_DIR}/${fileName}`,
      data: base64Data,
      directory: Directory.Data,
    });
 
    /*
    if (this.platform.is("hybrid")) {
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFile(savedFile.uri),
      };
    } else {
      return {
        filepath: fileName,
        webviewPath: photo.webPath,
      };
    }*/
  }

  private async readAsBase64(photo: Photo) {
    // "hybrid" will detect Cordova or Capacitor
    if (this.platform.is("hybrid")) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: photo.path,
      });

      return file.data;
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath);
      const blob = await response.blob();

      return (await this.convertBlobToBase64(blob)) as string;
    }
  }

  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  

  
}