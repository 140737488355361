import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Response } from "../../dto/response.dto";  
import { IAuth } from "../auth/iauth.interface";
import { ICommonControllerHelper } from "../../helpers/common/icommon-controller.helper";
import { Events, INotification } from "./inotification.interface";
import { NotificationDto } from "../../dto/notifications/notification.dto";
import { NotificationResponseDto } from "../../dto/notifications/notification-response.dto";

@Injectable({ providedIn: "root" })
export class NotificationService implements INotification {
  private postChannel:EventEmitter<NotificationDto[]> = new EventEmitter();
  private eventChannel:EventEmitter<Events> = new EventEmitter();
  private server_key: string = environment.server_key;
  private app_url: string = environment.APP_URL;
  private post:NotificationDto;
  constructor(
    private http: HttpClient,
    private auth: IAuth,
    private common: ICommonControllerHelper
  ) {
  } 
   
  getNotification(): NotificationDto {
    return this.post;
  }
  setNotification(model: NotificationDto) {
   this.post= model;
  }
  
  channel(): EventEmitter<NotificationDto[]> {
    return this.postChannel;
  }
  events(): EventEmitter<Events> {
    return this.eventChannel;
  }
   
  async requestNotification(): Promise<NotificationDto[]> {
    try {
      let udata = await this.auth.userData();
      var formData: any = new FormData();
    //  formData.append("user_id", udata.user_id);
      formData.append("fetch", "notifications");
      formData.append("server_key", this.server_key);
      formData.append("type", "get_news_feed");
      formData.append("limit", "100");
      this.eventChannel.emit(Events.loading);
      const url = `${this.app_url}get-general-data?access_token=${udata.access_token}`;
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as NotificationResponseDto;
      if (data.api_status == 200) {
        if (this.postChannel!=null){
          this.postChannel.emit(data.notifications);
          this.eventChannel.emit(Events.loadingEnd);
        }
        return data.notifications;
      }
    } catch (err) {
      // this.logger.error(err, '');
      throw err;
    }
  }
}
