import { GResponse } from '../../dto/response.dto';
import { Person } from '../../dto/user-data.dto'; 
export abstract class IGeneral {
 // abstract doLogin(user: Credentials): Promise<boolean>;
  abstract lookUpPerson(identification: string): Promise<GResponse<Person>> ;
  abstract register(person: Person): Promise<GResponse<Person>> ;
  abstract generic_register<T>(action:string,item: T): Promise<GResponse<T>>
  abstract getList<T>(action:string): Promise<GResponse<T>>;
 /* abstract userData(): Promise<UserDataDto>;
  abstract updateUserData(accountData: UserProfileDto) : Promise<UserInfoDto>;*/
}
