import { EventEmitter } from "@angular/core";
import { NotificationDto } from "../../dto/notifications/notification.dto";
 

export abstract class INotification {
  abstract channel(): EventEmitter<NotificationDto[]>;
  abstract events(): EventEmitter<Events>;
  abstract requestNotification(): Promise<NotificationDto[]>; 
  abstract setNotification(model:NotificationDto);
  abstract getNotification():NotificationDto;
}


export enum Events {
  loading,
  loadingEnd
}
export enum TypeNotificacion {
  comment = "comment",
  reaction = "reaction",
  shared_your_post= "shared_your_post"
}