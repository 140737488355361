import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { IAuth } from "../../services/auth/iauth.interface";


@Injectable({
    providedIn: 'root',
  })
  export class RequestInterceptor implements HttpInterceptor {
    headerData: any = {
        'Content-Type': 'application/json; charset=utf-8',
         Authorization: '',
      };
    constructor(private auth:IAuth){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {          
      return from(this.handle(req,next));
    }
    async handle(req: HttpRequest<any>, next: HttpHandler){ 
      let dataToken = await this.auth.userData();
      if (dataToken!=null){
        this.headerData.Authorization= `bearer ${dataToken.access_token}`;
      }
      req = req.clone({
        setHeaders: this.headerData,
        body: req.body,
      });
      return next.handle(req).toPromise();
    }

  }