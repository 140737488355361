import { EventEmitter } from "@angular/core";
import { Photo } from "@capacitor/camera";

export abstract class ICommonMediaHelper {
    abstract events(): EventEmitter<any>;
    abstract initMedia();
    abstract defaultImagePath(path:string);
    abstract takePicture();
    abstract savePicture(photo: Photo);
  }
  