import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { IAuth } from "../auth/iauth.interface";
import { ICommonControllerHelper } from "../../helpers/common/icommon-controller.helper";
import { Events, IFollow } from "./ifollow.interface";
import { SuggestionDto } from "../../dto/follow/suggestion.dto";
import { ResponseSuggestion } from "../../dto/follow/response-suggestion.dto";

@Injectable({ providedIn: "root" })
export class FollowService implements IFollow {
  private postChannel:EventEmitter<SuggestionDto[]> = new EventEmitter();
  private eventChannel:EventEmitter<Events> = new EventEmitter();
  private server_key: string = environment.server_key;
  private app_url: string = environment.APP_URL;
  private post:SuggestionDto;
  constructor(
    private http: HttpClient,
    private auth: IAuth,
    private common: ICommonControllerHelper
  ) {
  } 
  async follow(user_id: string): Promise<boolean> {
    try {
      let udata = await this.auth.userData();
      var formData: any = new FormData();
      formData.append("user_id", user_id);
      formData.append("server_key", this.server_key); 
      const url = `${this.app_url}follow-user?access_token=${udata.access_token}`;
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as any;
      if (data.api_status == 200) { 
        return true;
      }
    } catch (err) {
      // this.logger.error(err, ''); 
    }
    return false;
  }
   
  get(): SuggestionDto {
    return this.post;
  }
  set(model: SuggestionDto) {
   this.post= model;
  }
  
  channel(): EventEmitter<SuggestionDto[]> {
    return this.postChannel;
  }
  events(): EventEmitter<Events> {
    return this.eventChannel;
  }
   
  async request(): Promise<SuggestionDto[]> {
    try {
      let udata = await this.auth.userData();
      var formData: any = new FormData();
     //formData.append("user_id", udata.user_id);
      formData.append("fetch", "notifications");
      formData.append("server_key", this.server_key);
      formData.append("type", "get_news_feed");
      formData.append("limit", "100");
      this.eventChannel.emit(Events.loading);
      const url = `${this.app_url}get-user-suggestions?access_token=${udata.access_token}`;
      const data = (await this.http
        .post(url, formData)
        .toPromise()) as ResponseSuggestion;
      if (data.api_status == 200) {
        if (this.postChannel!=null){
          this.postChannel.emit(data.suggestions);
          this.eventChannel.emit(Events.loadingEnd);
        }
        return data.suggestions;
      }
    } catch (err) {
      // this.logger.error(err, '');
      throw err;
    }
  }
}
