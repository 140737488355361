import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Person, UserDataDto } from '../../dto/user-data.dto';
import { HttpClient } from '@angular/common/http'; 
import { StorageHelper } from '../../helpers/storage.helper';
import { ICommonControllerHelper } from '../../helpers/common/icommon-controller.helper';
import { IGeneral } from './igeneral.interface';
import { GResponse } from '../../dto/response.dto';

@Injectable({ providedIn: 'root' })
export class GeneralService implements IGeneral {
  private userInfo: UserDataDto = null;
  private server_key:string = environment.server_key;
  private app_url: string = environment.APP_URL;
  private LOCKUP_URL:string = environment.LOCKUP_URL;
  private REGISTER_URL:string = environment.REGISTER_URL;

  constructor(private http: HttpClient, private storage: StorageHelper,private loadingCtrl: ICommonControllerHelper) {}

  async lookUpPerson(identification: string): Promise<GResponse<Person>>{
    const x = new GResponse<Person>(); 
    x.isValid = false;
    x.message = [];
    try {
      const url = `${this.app_url}${this.LOCKUP_URL}?id=${identification}`;
      const data = await this.http.get<any>(url).toPromise();
      if (data.isValid){
        x.isValid = true;
        x.data = { 
          nombre: data.data.name,
          apellido: `${data.data.lastname} ${data.data.lastname2}`,
          datebirth: data.data.datebirth,
          gender: data.data.sexo,
          email:'',
          identification:data.data.identification,
          telefono:'',
          zona_id:''
        };
      }else{
        x.message = data.message;
      } 
      return  x;
    } catch (err) {
      // this.logger.error(err, 'basicLogin err'); 
    }
    x.message.push("Error, usuario no existe!");
    return  x;
  }
  async register(person: Person): Promise<GResponse<Person>>{
    const x = new GResponse<Person>(); 
    x.isValid = false;
    x.message = [];
    try {
      const url = `${this.app_url}${this.REGISTER_URL}`;
      const data = await this.http.post<GResponse<Person>>(url,person).toPromise();  
      if (data.isValid){
        x.isValid = true; 
        x.data=data.data; 
      }else{
        x.message  = data.message;
      }
      return  x;
    } catch (err) {
      x.message=err.error.message; 
    } 
    return  x;
  }
  async generic_register<T>(action:string,item: T): Promise<GResponse<T>>{
    const x = new GResponse<T>(); 
    x.isValid = false;
    x.message = [];
    try {
      const url = `${this.app_url}${action}`;
      const data = await this.http.post<GResponse<T>>(url,item).toPromise();  
      if (data.isValid){
        x.isValid = true; 
        x.data=data.data; 
      }else{
        x.message  = data.message;
      }
      return  x;
    } catch (err) {
      x.message=err.error.message; 
    } 
    return  x;
  }
 
  async getList<T>(action:string): Promise<GResponse<T>>{
    const x = new GResponse<T>(); 
    x.isValid = false;
    x.message = [];
    try {
      const url = `${this.app_url}${action}`;
      const data = await this.http.get<any>(url).toPromise();
      if (data.isValid){
        x.isValid = true;
        x.data =  data.data
      }else{
        x.message = data.message;
      } 
      return  x;
    } catch (err) {
      // this.logger.error(err, 'basicLogin err'); 
    }
    x.message.push("Error, usuario no existe!");
    return  x;
  }
}
